import React, { createContext, useContext, useEffect, useState } from "react";
import { SmallCloseIcon, QuestionOutlineIcon } from "@chakra-ui/icons";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
  useMediaQuery,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Tooltip,
  Collapse,
  Grid,
} from "@chakra-ui/react";
import * as Sentry from "@sentry/nextjs";
import { useOnboardingSteps } from "components/onboard-panel/useOnboardingSteps";
import { ModalInfoIcon } from "components/popups/ModalInfoIcon";
import { TweetPopup } from "components/popups/tweetPopup";
import { useSession } from "next-auth/react";
import { useRouter } from "next/dist/client/router";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import toast from "react-hot-toast";
import { radius } from "theme/foundations/radius";
import { layerStyle, textStyle, variant } from "theme/names";
import { v4 as uuidv4 } from "uuid";
import { AiVariationPopup } from "../components/popups/aiVariationPopup";
import {
  findNextSlot,
  isTweetFromAccount,
  saveSchedule,
} from "../controllers/schedule";
import { getPlan, isAllowed } from "../controllers/subscription";
import { firebaseAuth, firebaseClient } from "../firebaseClient";
import * as analytics from "../utils/analytics";
import { postData } from "../utils/helpers";
import { getAccount, getAuthUrl } from "../utils/sessionHelper";
import { useFirebaseUser } from "../utils/useFirebaseUser";
import { Schedule } from "components/schedule";
import { TweetLabels } from "components/tweet-labels";
import { WhenToPostData } from "./WhenToPostData";
import { BsFillCaretDownFill } from "react-icons/bs";
import { connectLinkedin } from "../controllers/linkedinController";

export const TweetContext = createContext({});

export function TweetWrapper({ children }) {
  const firstField = React.useRef();
  // const [text, setText] = React.useState("");

  const [tabIndex, setTabIndex] = React.useState(0);
  const [selectedTweet, setSelectedTweet] = React.useState(undefined);
  const [hasInit, setHasInit] = React.useState(false);
  const { data: session, status } = useSession() ?? {};
  const loading = status === "loading";

  const isAuthenticated = useFirebaseUser();
  const refComposer = React.createRef();
  const router = useRouter();

  const {
    isOpen: isOpenVariation,
    onOpen: onOpenVariation,
    onClose: onCloseVariation,
  } = useDisclosure();
  const {
    isOpen: isOpenVariationAuto,
    onOpen: onOpenVariationAuto,
    onClose: onCloseVariationAuto,
  } = useDisclosure();
  const focusRefVariation =
    React.useRef() as React.MutableRefObject<HTMLButtonElement>;
  const [isTooSmallForTweet] = useMediaQuery("(max-width: 1000px)");

  const {
    isOpen: isOpenEditSchedule,
    onOpen: onOpenEditSchedule,
    onClose: onCloseEditScheduleInternal,
  } = useDisclosure();
  const [schedule, setSchedule] = React.useState<any>([]);
  const [addTime, setAddTime] = React.useState(new Date(2020, 0, 1, 18, 0, 0));
  const [isEvergreen, setIsEvergreen] = React.useState(false);
  const [isRefreshingLinkedin, setIsRefreshingLinkedin] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isFullModeOpen, setIsFullModeOpen] = React.useState(false);

  const [isIncognitoMode, setIsIncognitoMode] = React.useState(false);
  const [incognitoCreator, setIncognitoCreator] = React.useState({});

  const [textState, setTextState] = useState<any>({
    text: "",
    typingTimeout: undefined,
    isSaved: false,
    scheduledTime: new Date(),
    files: [],
    threadTweets: [],
  });
  const [id, setId] = React.useState("");
  const [refresh, refresher] = React.useState<any>({});
  const [nextSlot, setNextSlot] = React.useState(new Date());
  const [isTweetTextChanged, setIsTweetTextChanged] = useState<boolean>(false);
  const [isOpenCredentials, setIsOpenCredentials] = React.useState(false);
  const onCloseCredentials = () => setIsOpenCredentials(false);
  const onOpenCredentials = () => setIsOpenCredentials(true);
  const extraAuthRef =
    React.useRef() as React.MutableRefObject<HTMLButtonElement>;
  const { updateOnboardSteps } = useOnboardingSteps();
  const [forceScheduledTime, setForceScheduledTime] =
    React.useState<boolean>(false);
  const [selectedLabelsIds, setSelectedLabelsIds] = useState<string[]>([]);
  const [selectedCompany, setSelectedCompany] = useState({});
  const [selectedTweetPreview, setSelectedTweetPreview] = useState({
    text: "",
    id: "",
  });
  const {
    isOpen: isOpenTweetPreview,
    onOpen: onOpenTweetPreview,
    onClose: onCloseTweetPreview,
  } = useDisclosure();

  const setFiles = (files) => {
    if (!files.length) return;
    // @ts-ignore
    const oldFileList = refComposer?.current?.textState().files || [];
    const newFilesList = Array.from(
      new Set([...oldFileList, ...files].filter(Boolean))
    );
    const textToAppend = newFilesList
      .map((f) => {
        // @ts-ignore
        if (refComposer?.current?.textState().text.includes(f)) return;
        return "[img:" + f + "]";
      })
      .join(" ");
    // @ts-ignore
    const oldText = refComposer?.current?.textState().text;
    const newText = oldText.length
      ? oldText + " " + textToAppend
      : textToAppend;
    setTextState({ ...textState, files: newFilesList, text: newText });
  };

  const getFiles = () => {
    // @ts-ignore
    return refComposer?.current?.textState().files || [];
  };

  const onCloseEditSchedule = () => {
    // refreshNextSlot();
    onCloseEditScheduleInternal();
  };

  const onClose = () => {
    //@ts-ignore
    setTextState(refComposer?.current?.textState());
    setIsOpen(false);
  };

  const onOpen = () => {
    // setInitText(textState.text ? textState.text : "");
    setTabIndex(0);
    setIsOpen(true);
  };

  useEffect(() => {
    if (isTweetTextChanged) {
      setIsTweetTextChanged(false);
    }
  }, [isTweetTextChanged]);

  // console.log("render TweetWrapper " + refComposer.current + " / ");

  const newTweet = async (
    tweet,
    forceId = "",
    animate = false,
    scheduledTime = undefined as any,
    callback = () => {}
  ) => {
    // console.log("newTweet");
    setSelectedTweet(undefined);
    const { text: defaultText = "", files = [] } = tweet;
    // console.log(defaultText.length);
    // setTextState({text: defaultText, typingTimeout: undefined, isSaved: (defaultText.length ? true : false)});

    // console.log("newTweet ref: " + refComposer.current);

    if (scheduledTime) {
      console.log("newTweet scheduledTime: " + scheduledTime);
      setNextSlot(new Date(scheduledTime));
    }
    setForceScheduledTime(false);

    if (refComposer.current) {
      // console.log("setTextState: " + defaultText);
      // console.log(files);

      let newState = {
        ...tweet,
        text: defaultText,
        threadTweets: [],
        typingTimeout: undefined,
        isSaved: defaultText.length ? true : false,
        scheduledTime: scheduledTime ? new Date(scheduledTime) : new Date(),
        files: files,
      };

      // console.log("refComposer.current.setTextState", newState);
      //@ts-ignore
      refComposer.current.setTextState(newState);

      //@ts-ignore
      refComposer.current.resetUploader(files);
    }

    if (animate) {
      let count = 0;
      while (count < defaultText.length) {
        setTextState({
          ...textState,
          text: defaultText.substring(0, count),
        });
        count += 5;
        await new Promise((resolve) => setTimeout(resolve, 1));
      }
      setTextState({
        ...textState,
        text: defaultText,
      });
      callback();
    } else {
      // console.log("refComposer null: " + defaultText);
      // setInitText(defaultText);
      let newState = {
        ...textState,
        statusGhost: "",
        ...tweet,
        text: defaultText,
        threadTweets: [],
        files: files,
        scheduledTime: scheduledTime ? new Date(scheduledTime) : new Date(),
      };

      // if (scheduledTime)
      //     newState.scheduledTime = scheduledTime;

      // console.log("setTextState", newState);
      setTextState(newState);
    }

    if (defaultText.length > 0 && !forceId) {
      let newId = uuidv4();
      setId(newId);
      createDraft({ text: defaultText }, newId);
    } else if (forceId) {
      setId(forceId);
    } else {
      let newId = uuidv4();
      setId(newId);
    }

    //@ts-ignore
    if (refComposer?.current?.simulateInput) {
      //@ts-ignore
      refComposer.current.simulateInput();
    } else {
      console.log("refComposer: " + refComposer?.current);
    }
  };

  const createDraft = async (data, newId) => {
    // console.log("createDraft newId: " + newId);

    try {
      if (!isAuthenticated) {
        console.log(
          "createDraft - App not fully loaded yet, please retry later"
        );
        // toast.error("App not fully loaded yet, please retry later");
        // Sentry.captureException(new Error("createDraft - App not fully loaded yet, please retry later"));
        return;
      }

      if (!newId) {
        console.error("createDraft - ID is undefined");
        Sentry.captureException(new Error("createDraft - ID is undefined"));
      }

      // let newId = uuidv4();
      // setId(newId);

      if (getAccount(session)?.id) {
        let newTweet = {
          id: newId,
          idAccount: getAccount(session).idAccount,
          text: data.text ?? "",
          threadTweets: [],
          status: "draft",
          dateCreated: new Date(),
          profilePic: getAccount(session).image,
          name: getAccount(session).name,
          userId: getAccount(session).id,
          isInfluentLeaders: true,
          ...data,
        };
        // console.log({newTweet});
        const db = firebaseClient.firestore();
        await db
          .collection("users")
          .doc(getAccount(session).id)
          .collection("tweets")
          .doc(newId)
          .set(newTweet);
        // console.log("draft created");
      }
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
      // toast.error("An error happened while creating a draft");
      return false;
    }

    return true;
  };

  React.useEffect(() => {
    if (session?.user?.firebaseToken) {
      firebaseAuth(session);
    }
  }, [session]);

  const requestVariationAuto = (tw) => {
    setSelectedTweet(tw);
    onOpenVariationAuto();
  };

  const refreshNextSlot = async (scheduleTweets) => {
    console.log("refreshNextSlot");

    if (!scheduleTweets) {
      const db = firebaseClient.firestore();
      let docs = await db
        .collection("users")
        .doc(getAccount(session).id)
        .collection("tweets")
        .where("status", "==", "scheduled")
        .orderBy("scheduledTime", "asc")
        .get();
      // console.log("nb docs: " + docs.size);
      scheduleTweets = [];
      docs.forEach((doc) => {
        let data = doc.data();
        if (isTweetFromAccount(data, session)) {
          scheduleTweets.push(data);
        }
      });
    }

    let date = findNextSlot(session, scheduleTweets);
    console.log("slot found: " + date);
    if (date) setNextSlot(date);
  };

  const requestVariation = (tw) => {
    // console.log("requestVariation: " + session?.user?.data?.credits);
    if (session?.user?.data?.credits == 0) {
      console.log("no more credits");
      analytics.log("requestVariation_noMoreCredits", {
        ...session?.user?.data,
      });
    }

    setSelectedTweet(tw);
    onOpenVariation();
  };

  const getRandomIntFromInterval = (min: number = -5, max: number = 5) =>
    Math.floor(Math.random() * (max - min + 1) + min);

  const naturalizeSchedule = () => {
    if (!schedule) {
      toast.error("No Schedule found!");
      return;
    }

    const newSchedule = schedule.map((s) => {
      let newMinute = s.minute;
      let newHour = s.hour;

      if (s.minute < 55 && s.minute > 4) {
        newMinute = s.minute + getRandomIntFromInterval();
      }
      if (s.minute <= 4) {
        const randomNumber = getRandomIntFromInterval();

        if (randomNumber + s.minute < 0) {
          if (s.hour !== 0) {
            newMinute = 60 + randomNumber;
            newHour = s.hour - 1;
          } else {
            newMinute = s.minute + getRandomIntFromInterval(1, 5);
          }
        } else {
          newMinute = s.minute + randomNumber;
        }
      }
      if (s.minute >= 55) {
        const randomNumber = getRandomIntFromInterval();

        if (randomNumber + s.minute > 59) {
          if (s.hour !== 23) {
            newMinute = randomNumber + s.minute - 60;
            newHour = s.hour + 1;
          } else {
            newMinute = s.minute + getRandomIntFromInterval(-5, -1);
          }
        } else {
          newMinute = s.minute + randomNumber;
        }
      }
      return { ...s, minute: newMinute, hour: newHour };
    });

    setSchedule([...newSchedule]);
    saveSchedule(session, newSchedule, refresher);
  };

  const handleAddSlot = async () => {
    if (
      schedule?.length &&
      schedule.some(
        (x) =>
          x.hour === addTime.getHours() && x.minute === addTime.getMinutes()
      )
    ) {
      toast.error(
        "Can't add a 2nd slot at the exact same time as another slot."
      );
      return;
    }

    let time = {
      hour: addTime.getHours(),
      minute: addTime.getMinutes() ?? 0,
      days: [true, true, true, true, true, true, true],
      isEvergreen: isEvergreen,
      labels: selectedLabelsIds,
    };

    if (
      time.hour < 0 ||
      time.hour > 24 ||
      time.minute < 0 ||
      time.minute > 60
    ) {
      toast.error("Invalid slot configuration");
      return;
    }

    let newSchedule = schedule;

    if (!newSchedule) newSchedule = [];

    newSchedule.push(time);
    newSchedule = newSchedule.sort(
      (a, b) =>
        parseFloat(
          "" + a.hour + "." + (a.minute < 10 ? "0" + a.minute : a.minute)
        ) -
        parseFloat(
          "" + b.hour + "." + (b.minute < 10 ? "0" + b.minute : b.minute)
        )
    );
    setSchedule([...newSchedule]);
    setSelectedLabelsIds([]);
    saveSchedule(session, newSchedule, refresher);
  };

  let sharedState = {
    isOpen: isOpen,
    setIsOpen: setIsOpen,
    open: onOpen,
    close: onClose,
    newTweet: newTweet,
    isFullModeOpen,
    setIsFullModeOpen,
    isIncognitoMode,
    setIsIncognitoMode,
    incognitoCreator,
    setIncognitoCreator,
    setFiles,
    getFiles,
    setSelectedTweet: setSelectedTweet,
    selectedTweet: selectedTweet,
    requestVariation: requestVariation,
    requestVariationAuto: requestVariationAuto,
    createDraft: createDraft,
    textState: textState,
    setTextState: setTextState,
    refComposer: refComposer,
    id: id,
    onOpenEditSchedule: onOpenEditSchedule,
    tabIndex: tabIndex,
    setTabIndex: setTabIndex,
    setSchedule: setSchedule,
    schedule: schedule,
    setNextSlot: setNextSlot,
    nextSlot: nextSlot,
    refreshNextSlot: refreshNextSlot,
    refresher: refresher,
    refresh: refresh,
    onOpenCredentials: onOpenCredentials,
    onCloseCredentials: onCloseCredentials,
    isTweetTextChanged,
    setIsTweetTextChanged,
    setSelectedTweetPreview,
    selectedTweetPreview,
    isOpenTweetPreview,
    onOpenTweetPreview,
    onCloseTweetPreview,
    setSelectedCompany,
    selectedCompany,
    setForceScheduledTime,
    forceScheduledTime,
  };

  // console.log("render tweetContext " + textState.threadTweets);
  // console.log("Token: " + session?.user?.firebaseToken + " - user id: " + session?.user?.uid + " - connected as: " + getAccount(session)?.id);
  // console.log("TweetContext - scheduledTime: " + textState.scheduledTime);

  const [showNewSlot, showNewSlotSet] = useState(false);
  const [showStats, showStatsSet] = useState(false);

  return (
    <TweetContext.Provider value={sharedState}>
      {children}

      <AiVariationPopup
        isOpen={isOpenVariationAuto}
        onClose={onCloseVariationAuto}
        selectedTweet={selectedTweet}
        currentTempLevel={session?.user?.data?.tempLevel}
        newTweet={newTweet}
        onOpenScheduler={onOpen}
      />
      <AlertDialog
        isOpen={isOpenVariation}
        //@ts-ignore
        leastDestructiveRef={focusRefVariation}
        onClose={onCloseVariation}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader
            // fontSize="lg" fontWeight="bold"
            >
              {/* <ModalInfoIcon /> */}
              Request tweet variations
            </AlertDialogHeader>

            <AlertDialogBody>
              {
                //@ts-ignore
                session?.user?.data?.credits && session?.user?.data?.credits > 0
                  ? `This action will cost you 1 credit. You will have ${
                      session.user.data.credits - 1
                    } left.`
                  : isAllowed(session?.user, "ghostwriting")
                  ? "You can't perform this action, not enough credits. Credits will be reseted on the 1st of each month."
                  : "You can't perform this action, not enough credits."
              }
              <Text
                textStyle={textStyle["body.medium.light"]}
                mt={4}
                fontSize="sm"
              >
                Variations will be written manually by our team of ghostwriters.
                The process can take up to 48 hours.
              </Text>
              <Text
                textStyle={textStyle["body.medium.light"]}
                mt={4}
                fontSize="sm"
              >
                Once ready, you can find them in the section "My Variations".
              </Text>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button variant="tertiary" onClick={onCloseVariation}>
                Cancel
              </Button>
              {session?.user?.data?.credits &&
              session?.user?.data?.credits > 0 ? (
                <Button
                  ref={focusRefVariation}
                  variant={variant.Button.primary}
                  // colorScheme="blue"
                  onClick={async () => {
                    //@ts-ignore
                    session.user.data.credits--;
                    if (session?.user?.uid && selectedTweet) {
                      postData({
                        url: "user-requestVariation",
                        token: "",
                        data: {
                          idUser: session.user.uid,
                          //@ts-ignore
                          twUserName: session.user.data.twUserName,
                          tweet: selectedTweet,
                        },
                      });
                      toast.success("Variation requested successfully", {
                        style: { background: "gray.600", color: "#222" },
                      });
                      //@ts-ignore
                      analytics.log("hit_request_variations", {
                        ...analytics.formatTweet(selectedTweet),
                      });
                    } else {
                      toast.error("An error occurred", {
                        style: { background: "gray.600", color: "#222" },
                      });
                    }
                    onCloseVariation();
                  }}
                  // ml={3}
                >
                  Confirm
                </Button>
              ) : ["start", "grow"].includes(getPlan(session?.user)) ? (
                <Button
                  variant={variant.Button.primary}
                  ref={focusRefVariation}
                  // ml={3}
                  // colorScheme="blue"
                  onClick={() => {
                    router.push("/pricing");
                    onCloseVariation();
                  }}
                >
                  Upgrade
                </Button>
              ) : (
                <Box></Box>
              )}
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <Modal
        isOpen={isOpenEditSchedule}
        onClose={onCloseEditSchedule}
        size="2xl"
      >
        <ModalOverlay />
        <ModalContent px="6" py="8">
          <ModalHeader>
            {/* <ModalInfoIcon /> */}
            Edit your post schedule
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Box overflow="scroll" className="noScrollBar">
              <Table size="sm" variant="simple">
                <Thead>
                  <Tr>
                    <Th {...{ textStyles: "small.bold.standard" }}>Time</Th>
                    {["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].map(
                      (day, index) => (
                        <Th
                          // {...{ textStyles: "small.bold.standard" }}
                          key={day}
                        >
                          {day}
                        </Th>
                      )
                    )}
                  </Tr>
                </Thead>
                {/* <Divider {...{w: "full"}} /> */}
                <Tbody>
                  {schedule &&
                    schedule.map((time: any, index) => (
                      <Schedule
                        key={"Schedule" + index}
                        time={time}
                        index={index}
                        onDelete={() => {
                          schedule.splice(index, 1);
                          setSchedule([...schedule]);
                          saveSchedule(session, schedule, refresher);
                        }}
                        onChange={(indexDay, isChecked) => {
                          //@ts-ignore
                          time.days[indexDay] = isChecked;
                          setSchedule([...schedule]);
                          saveSchedule(session, schedule, refresher);
                        }}
                      />
                    ))}
                </Tbody>
              </Table>
            </Box>
            {/* <Box textAlign="center" mb="5">
                          <Tooltip label="This will randomly add or subtract up to 5 minutes to your posting time to make them more natural and avoid pick posting hours.">
                            <Button size="sm" onClick={naturalizeSchedule}>Make my schedule more natural</Button>
                          </Tooltip>
                        </Box> */}
            <Grid {...{ gap: 4 }}>
              <Flex {...{ gap: 4 }}>
                <Button
                  placeSelf="center"
                  variant="secondary"
                  onClick={() => {
                    showNewSlotSet(!showNewSlot);
                  }}
                  leftIcon={
                    <svg
                      width="13"
                      height="14"
                      viewBox="0 0 13 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13 6.5C13 6.9375 12.6562 7.25 12.25 7.25H7.25V12.25C7.25 12.6875 6.90625 13.0312 6.5 13.0312C6.0625 13.0312 5.75 12.6875 5.75 12.25V7.25H0.75C0.3125 7.25 0 6.9375 0 6.53125C0 6.09375 0.3125 5.75 0.75 5.75H5.75V0.75C5.75 0.34375 6.0625 0.03125 6.5 0.03125C6.90625 0.03125 7.25 0.34375 7.25 0.75V5.75H12.25C12.6562 5.75 13 6.09375 13 6.5Z"
                        fill="currentColor"
                      />
                    </svg>
                  }
                >
                  Add a new slot
                </Button>
                <Button
                  placeSelf="center"
                  variant="tertiary"
                  onClick={() => {
                    showStatsSet(!showStats);
                  }}
                  leftIcon={
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.5 1C8.3125 1 9 1.6875 9 2.5V13.5C9 14.3438 8.3125 15 7.5 15H6.5C5.65625 15 5 14.3438 5 13.5V2.5C5 1.6875 5.65625 1 6.5 1H7.5ZM7.5 2.5H6.5V13.5H7.5V2.5ZM2.5 7C3.3125 7 4 7.6875 4 8.5V13.5C4 14.3438 3.3125 15 2.5 15H1.5C0.65625 15 0 14.3438 0 13.5V8.5C0 7.6875 0.65625 7 1.5 7H2.5ZM2.5 8.5H1.5V13.5H2.5V8.5ZM10 4.5C10 3.6875 10.6562 3 11.5 3H12.5C13.3125 3 14 3.6875 14 4.5V13.5C14 14.3438 13.3125 15 12.5 15H11.5C10.6562 15 10 14.3438 10 13.5V4.5ZM11.5 13.5H12.5V4.5H11.5V13.5Z"
                        fill="currentColor"
                      />
                    </svg>
                  }
                  // right icon caret down
                  rightIcon={<Icon as={BsFillCaretDownFill} fontSize="12px" />}
                >
                  Post performance data
                </Button>
              </Flex>
              <Collapse in={showNewSlot}>
                <Box
                  // borderRadius={radius.lg}
                  layerStyle={layerStyle["bg.border.rounded"]}
                  textStyle="body.medium.standard"
                  p="2"
                  m="2"
                  py="4"
                >
                  <HStack
                    width="100%"
                    // flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    // color="#1E1E1E"
                    // spacing={5}
                    flexWrap="wrap"
                  >
                    {/* <Flex mb={5}> */}
                    <Button
                      bg={useColorModeValue("white", "#222")}
                      p={0}
                      w="150px"
                      m={2}
                    >
                      <Datetime
                        initialValue={addTime}
                        dateFormat={false}
                        timeFormat="hh:mm A"
                        inputProps={{ className: "customPicker" }}
                        onChange={(d) => {
                          //@ts-ignore
                          d.toDate && setAddTime(d.toDate());
                        }}
                      />
                    </Button>
                    {session?.user?.data?.hasEvergreen && (
                      <Checkbox
                        colorScheme="blue"
                        color={useColorModeValue("#1E1E1E", "white")}
                        defaultChecked={false}
                        onChange={async (e) => {
                          setIsEvergreen(e.target.checked);
                        }}
                      >
                        Evergreen slot 🌲
                      </Checkbox>
                    )}
                    {/* </Flex> */}
                    <Button
                      variant="primary"
                      leftIcon={
                        <svg
                          width="12"
                          height="12"
                          viewBox="0 0 15 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.5 7C8.59375 7 9.5 7.90625 9.5 9C9.5 10.125 8.59375 11 7.5 11C6.375 11 5.5 10.125 5.5 9C5.5 7.90625 6.375 7 7.5 7ZM14.0312 3.0625C14.3125 3.34375 14.5 3.75 14.5 4.125V12C14.5 13.125 13.5938 14 12.5 14H2.5C1.375 14 0.5 13.125 0.5 12V2C0.5 0.90625 1.375 0 2.5 0H10.375C10.75 0 11.1562 0.1875 11.4062 0.4375L14.0312 3.0625ZM4.5 1.5V4H9V1.5H4.5ZM13 12V4.21875C13 4.15625 12.9688 4.125 12.9375 4.09375L10.5 1.625V4.75C10.5 5.1875 10.1562 5.5 9.75 5.5H3.75C3.3125 5.5 3 5.1875 3 4.75V1.5H2.5C2.21875 1.5 2 1.75 2 2V12C2 12.2812 2.21875 12.5 2.5 12.5H12.5C12.75 12.5 13 12.2812 13 12Z"
                            fill="currentColor"
                          />
                        </svg>
                      }
                      onClick={handleAddSlot}
                    >
                      Save Slot
                    </Button>
                  </HStack>

                  {/* <WhenToPostData {...{showStats, showStatsSet}} /> */}

                  <Accordion allowToggle mt="2">
                    <AccordionItem border="none">
                      <AccordionButton
                        width="auto"
                        marginX="auto"
                        _focus={{
                          boxShadow: "none",
                        }}
                        _hover={{
                          bg: "none",
                        }}
                      >
                        <Box flex="1" textAlign="left">
                          Advanced Options
                        </Box>
                        <AccordionIcon ml="1" />
                      </AccordionButton>
                      <AccordionPanel textAlign="center" pb="2">
                        <Flex justifyContent="center" alignItems="center">
                          <Text fontWeight="semibold">
                            Assign a label to your new slot
                          </Text>
                          <Tooltip
                            label={
                              <>
                                <Text>
                                  You can use labels to categorize your posts
                                  (topics, format, etc.).
                                </Text>
                                <br />
                                <Text>
                                  By assigning a label to a slot, you can
                                  organize your weekly queue in a way that
                                  diversifies the type of content you post
                                  (promotional, entertaining, high-value, etc.).
                                </Text>
                                <br />
                                <Text>
                                  Create a slot with a label assigned to it, and
                                  then assign the label to your post before
                                  adding it to your queue using the drop down
                                  menu in the composer. It will automatically be
                                  added to the next available slot that has the
                                  proper label assigned to it.
                                </Text>
                              </>
                            }
                          >
                            <QuestionOutlineIcon ml="3" />
                          </Tooltip>
                        </Flex>
                        <Box mt="4">
                          <TweetLabels
                            selectedLabelIds={selectedLabelsIds}
                            onChange={(labels, callback) => {
                              if (labels.length > 1) {
                                toast.error(
                                  "You can only assign 1 label to a queue slot"
                                );
                                callback && callback(true);
                                return;
                              }

                              setSelectedLabelsIds(labels);
                              callback && callback();
                            }}
                          />
                        </Box>
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                </Box>
              </Collapse>
              <WhenToPostData {...{ showStats, showStatsSet }} />
            </Grid>
          </ModalBody>
          <ModalFooter>
            <Box></Box>
            <Center>
              <Button
                variant="tertiary"
                textDecoration="underline"
                fontWeight="400"
                fontSize="sm"
                zIndex="-1"
                onClick={() => {
                  router.push("/settings");
                  onCloseEditSchedule();
                  onClose();
                }}
              >
                <Icon viewBox="0 0 16 16" mr={2}>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8 0C12.4062 0 16 3.59375 16 8C16 12.4375 12.4062 16 8 16C3.5625 16 0 12.4375 0 8C0 3.59375 3.5625 0 8 0ZM8 14.5C8.21875 14.5 8.8125 14.2812 9.46875 13C9.75 12.4375 9.96875 11.7812 10.1562 11H5.8125C6 11.7812 6.21875 12.4375 6.5 13C7.15625 14.2812 7.75 14.5 8 14.5ZM5.5625 9.5H10.4062C10.4688 9.03125 10.5 8.53125 10.5 8C10.5 7.5 10.4688 7 10.4062 6.5H5.5625C5.5 7 5.5 7.5 5.5 8C5.5 8.53125 5.5 9.03125 5.5625 9.5ZM10.1562 5C9.96875 4.25 9.75 3.59375 9.46875 3.03125C8.8125 1.75 8.21875 1.5 8 1.5C7.75 1.5 7.15625 1.75 6.5 3.03125C6.21875 3.59375 6 4.25 5.8125 5H10.1562ZM11.9062 6.5C11.9688 7 12 7.5 12 8C12 8.53125 11.9688 9.03125 11.9062 9.5H14.3125C14.4375 9.03125 14.5 8.53125 14.5 8C14.5 7.5 14.4375 7 14.3125 6.5H11.9062ZM10.6875 2.09375C11.125 2.90625 11.4688 3.90625 11.6875 5H13.75C13.0938 3.71875 12 2.6875 10.6875 2.09375ZM5.28125 2.09375C3.96875 2.6875 2.875 3.71875 2.21875 5H4.28125C4.5 3.90625 4.84375 2.90625 5.28125 2.09375ZM1.5 8C1.5 8.53125 1.53125 9.03125 1.65625 9.5H4.0625C4 9.03125 4 8.53125 4 8C4 7.5 4 7 4.0625 6.5H1.65625C1.53125 7 1.5 7.5 1.5 8ZM13.75 11H11.6875C11.4688 12.125 11.125 13.125 10.6875 13.9375C12 13.3438 13.0938 12.3125 13.75 11ZM4.28125 11H2.21875C2.875 12.3125 3.96875 13.3438 5.28125 13.9375C4.84375 13.125 4.5 12.125 4.28125 11Z"
                      fill="currentColor"
                    />
                  </svg>
                </Icon>
                Timezone: {getAccount(session)?.timezone}
              </Button>
            </Center>
            <Box></Box>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <AlertDialog
        isOpen={isOpenCredentials}
        onClose={onCloseCredentials}
        //@ts-ignore
        leastDestructiveRef={extraAuthRef}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader
            // fontSize="lg" fontWeight="bold"
            >
              {/* <ModalInfoIcon /> */}
              {"One more step"}
            </AlertDialogHeader>
            <AlertDialogBody>
              <Flex p={5} pt={0} flexDirection="column" alignItems="center">
                <Text textStyle={textStyle["body.medium.light"]}>
                  {
                    "To publish posts, we need to refresh your Linkedin connection."
                  }
                </Text>
                <Text textStyle={textStyle["body.medium.light"]} mt={5}>
                  {"Hit the button and let our scheduler work wonders for you."}
                </Text>
                <Text textStyle={textStyle["body.medium.light"]} mt={5}>
                  {"Then, refresh this page."}
                </Text>
                <Button
                  mt={10}
                  maxW="300px"
                  variant={variant.Button.primary}
                  // colorScheme="twitter"
                  //@ts-ignore
                  ref={extraAuthRef}
                  // href={getAuthUrl(session)}
                  isDisabled={!session?.user?.uid}
                  isLoading={isRefreshingLinkedin}
                  onClick={async () => {
                    analytics.log("hit_getExtraAuth");
                    console.log("refresh li connection");

                    const accountSetupResult: any = await connectLinkedin(
                      session,
                      setIsRefreshingLinkedin
                    );
                    if (accountSetupResult?.success === 1) {
                      router.reload();
                    }
                  }}
                >
                  Connect your Linkedin Account
                </Button>
              </Flex>
            </AlertDialogBody>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <TweetPopup
        isOpen={isOpenTweetPreview}
        onClose={onCloseTweetPreview}
        text={selectedTweetPreview?.text}
        id={selectedTweetPreview?.id}
        tweet={selectedTweetPreview}
      />
    </TweetContext.Provider>
  );
}

export function useTweetContext() {
  return useContext(TweetContext);
}
