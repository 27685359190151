import {
  useState,
  useRef,
  useEffect,
  useContext,
  useImperativeHandle,
  forwardRef,
} from "react";
import dynamic from "next/dynamic";
import { useSession } from "next-auth/react";
import {
  Box,
  Flex,
  IconButton,
  Button,
  useColorModeValue,
  useDisclosure,
  Text,
  HStack,
  Tooltip,
  Divider,
} from "@chakra-ui/react";
import { useWindowWidth } from "@react-hook/window-size";
import { AiOutlineClose, AiOutlineSave } from "react-icons/ai";
import toast from "react-hot-toast";
import { v4 as uuid } from "uuid";
import { ImperativeHandle, EditorRef } from "./imperative-handle";
import { useTweetContext } from "context/tweetContext";
import { SettingsButton } from "./settings-button";
import { SendIcon } from "./icons/send-icon";
import { GRADIENT_COLOR, Prompt, SavedAction } from "./utils";
import { ActionLibrary } from "./action-library";
import { getAccount, updateUser } from "utils/sessionHelper";
import { firebaseClient, getToken } from "firebaseClient";
import * as analytics from "utils/analytics";
import { editIconLight } from "./icons/edit-icon";
import { copyIcon } from "./icons/copy-icon";
import { PlusIcon } from "./icons/plus-icon";
import { useCredit } from "controllers/subscription";
import { MainContext } from "context/mainContext";
import { RefreshIcon } from "./icons/refresh-icon";
import { useChatContext } from "context/chatContext";
import { FiSquare } from "react-icons/fi";
import { Messages } from "./messages";
import { Layout } from "./layout";
import { MdOutlineHistory } from "react-icons/md";
import { BiLibrary } from "react-icons/bi";
import { CloseLibrary } from "./icons/close-library";
import { History, Conversation } from "./history";
import { useFirebaseUser } from "utils/useFirebaseUser";
import moment from "moment";
import { CloseIcon } from "@chakra-ui/icons";

const Editor = dynamic(() => import("./editor"), { ssr: false });

type Panels = "action library" | "history";

export const ChatAssist = forwardRef((_, ref) => {
  const [htmlPrompt, setHtmlPrompt] = useState<string>("");
  const [actionsLibrarySearchTerm, setActionsLibrarySearchTerm] = useState("");
  const [messages, setMessages] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [activeIndex, setActiveIndex] = useState<any>();
  const [finishedAudio, setfinishedAudio] = useState<HTMLAudioElement | null>(
    null
  );
  const [isSavingAction, setIsSavingAction] = useState<boolean>(false);
  const [savedActions, setSavedActions] = useState<SavedAction[]>([]);
  const [hasSetSavedAction, setHasSetSavedAction] = useState<boolean>(false);
  const [activePanel, setActivePanel] = useState<Panels>("action library");
  const [historySearchTerm, setHistorySearchTerm] = useState<string>("");
  const [currentConversation, setCurrentConversation] = useState<
    Conversation | undefined
  >(undefined);
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [hasFetchedConversations, setHasFetchedConversations] =
    useState<boolean>(false);
  const [useMainSystemPrompt, setUseMainSystemPrompt] = useState<boolean>(true);
  const editorRef = useRef<EditorRef | null>(null);
  const bottomContainerRef = useRef<any>(null);
  const controllerRef = useRef<AbortController | null>();
  const screenWidth = useWindowWidth();
  const tweetContext: any = useTweetContext();
  const chatContext: any = useChatContext();
  const popupBg = useColorModeValue("white", "#1E1E1E");
  const borderColor = useColorModeValue(
    "border.lightMode.light",
    "border.darkMode.light"
  );
  const stopGeneratingColor = useColorModeValue("#2D64BC", "cyan.500");
  const panelButtonsColor = useColorModeValue("#0A65C1", "cyan.600");
  const {
    isOpen: isSidebarOpen,
    onToggle: onToggleSidebar,
    onOpen: onOpenSidebar,
    onClose: onCloseSidebar,
  } = useDisclosure();
  const { data: session } = useSession() ?? {}
  const isAuthenticated = useFirebaseUser();
  const mainContext: any = useContext(MainContext);
  const isTooSmall = screenWidth < 800;

  useEffect(() => {
    setfinishedAudio(new Audio("/assets/audio/finished_chat_response.mp3"));
  }, []);

  useEffect(() => {
    scrollToBottom();
    handleCopyAndEditPost();
  }, [messages]);

  useEffect(() => {
    handleCopyAndEditPost();
  }, [chatContext?.isOpen, tweetContext?.refComposer]);

  const handleCopyAndEditPost = () => {
    // on click on chat-to-copy, copy the text to clipboard
    const elements = document.getElementsByClassName("formatted-chat");
    // console.log('elements:', elements)

    if (elements?.length) {
      for (var i = 0; i < elements.length; i++) {
        // console.log('elements:', elements[i])
        // console.log('elements:', elements[i].childNodes)

        let elementText = elements[i].childNodes[0];
        // let elementButtonCopy = elements[i].childNodes[0];
        let elementButtonCopy = elements[i].childNodes[1].childNodes[0];
        let elementButtonEdit = elements[i].childNodes[1].childNodes[1];

        let elementButtonCopyClone = elementButtonCopy.cloneNode(true);
        elementButtonCopy?.parentNode?.replaceChild(
          elementButtonCopyClone,
          elementButtonCopy
        );
        elementButtonCopyClone.addEventListener("click", async (event) => {
          event.preventDefault();
          const linkText = elementText.textContent;
          if (linkText) {
            try {
              await navigator.clipboard.writeText(linkText);
              toast.success("text copied to clipboard!");
            } catch (err) {
              console.error("Failed to copy link: ", err);
            }
          }
        });

        let elementButtonEditClone = elementButtonEdit.cloneNode(true);
        elementButtonEdit?.parentNode?.replaceChild(
          elementButtonEditClone,
          elementButtonEdit
        );
        elementButtonEditClone.addEventListener("click", async (event) => {
          event.preventDefault();
          const linkText = elementText.textContent;
          if (linkText) {
            try {
              tweetContext.newTweet(
                { text: linkText },
                undefined,
                undefined,
                undefined,
                () => {
                  tweetContext.setIsTweetTextChanged(true);
                }
              );
              tweetContext.open();
              tweetContext.refComposer.current?.focus();
            } catch (err) {
              console.error("Failed to copy link: ", err);
            }
          }
        });
      }
    }
  };

  useEffect(() => {
    const composerElements = document.getElementsByClassName("with-tooltip");
    for (let i = 0; i < composerElements.length; i++) {
      const element = composerElements.item(i);

      element?.addEventListener("mouseover", (e) => {
        // @ts-ignore
        const text = e?.target?.dataset?.tooltip;
        const tooltipElement = document.getElementById("chat-assist-tooltip");
        if (tooltipElement && text) {
          tooltipElement.innerHTML = text;
          tooltipElement.style.visibility = "visible";
          // @ts-ignore
          tooltipElement.style.top = e.y + 15 + "px";
          // @ts-ignore
          tooltipElement.style.left = e.x + 15 + "px";
        }
      });

      element?.addEventListener("mouseout", () => {
        const tooltipElement = document.getElementById("chat-assist-tooltip");
        if (tooltipElement) {
          tooltipElement.innerHTML = "";
          tooltipElement.style.visibility = "hidden";
        }
      });
    }
  }, [messages, chatContext?.isOpen]);

  // silent prompt is used here to pass system prompt which comes directly from tweet-composer AI Options
  const sendMessage = async (
    forceText = "",
    systemPrompt?: string,
    forceMessages?: any[],
    forcedComposerContent?: string
  ) => {
    try {
      // if (getAccount(session)?.creditsChat < 1) {
      //   toast.error("Woops! you are out of credits. You'll get new credits next month or ugrade the plan")
      //   return;
      // }

      if (!getAccount(session)?.subscription?.isSubscribed) {
        mainContext?.onOpenUpgrade();
        return;
      }

      if (isLoading) {
        toast.error("You can ask one thing at a time");
        return;
      }
      const composerContentTag = `<highlight-input class="remirror-mention-atom remirror-mention-atom-highlight-input" data-mention-atom-id="[composer content]" data-mention-atom-name="highlight-input">[composer content]</highlight-input>`;
      const tagsToIgnore = [composerContentTag];
      let htmlPromptAfterIgnoring = htmlPrompt;
      tagsToIgnore.forEach((tag) => {
        htmlPromptAfterIgnoring = htmlPromptAfterIgnoring.replaceAll(tag, "");
      });

      if (
        !forceText &&
        htmlPromptAfterIgnoring.includes("<highlight-input class")
      ) {
        toast.error("Please update your prompt");
        return;
      }

      let textToSend = forceText || editorRef.current?.getTextCustom();
      textToSend = textToSend?.trim();

      if (!textToSend) {
        toast.error("Please input something");
        return;
      }

      let content = textToSend;
      let contentFormatted, composerContent;
      let editorText = htmlPrompt;

      if (textToSend.includes("[composer content]")) {
        content = textToSend.replaceAll(
          "[composer content]",
          forcedComposerContent
            ? `"${forcedComposerContent}"`
            : `"${tweetContext?.refComposer?.current?.textState()?.text}"`
        );
        composerContent =
          forcedComposerContent ||
          tweetContext?.refComposer?.current?.textState()?.text;
        if (forceText) {
          editorText = textToSend.replaceAll(
            "[composer content]",
            composerContentTag
          );
        }

        const tooltipText =
          forcedComposerContent ||
          tweetContext?.refComposer?.current?.textState()?.text;
        const newText = `<div class="with-tooltip" data-tooltip="${tooltipText}">[composer content]</div>`;
        contentFormatted =
          textToSend.replaceAll("[composer content]", newText) || content;
      }

      const formattedMessage = {
        role: "user",
        content,
        contentFormatted,
        editorText,
        composerContent,
      };
      const updatedMessages = forceMessages ?? [...messages];

      // remove first element of messages
      let formattedMessages = updatedMessages?.slice(1);
      // There is this error (https://www.loom.com/share/25c0209755574ff3bf9a4b6fbc11f046) sometimes
      // and I think its because of this so from now it won't be added when chat assist is called using AI Options popup
      if (!systemPrompt) {
        // insert new message at the beginning
        formattedMessages.unshift({
          role: "system",
          content: `User self description: "${getAccount(session)?.description
            }"\nUser main topics: "${getAccount(session)?.keywords.join(
              ", "
            )}"\nUser name: "${getAccount(session)?.name}"`,
        });
      }

      if (systemPrompt) {
        formattedMessages.push({ role: "system", content: systemPrompt });
        setUseMainSystemPrompt(false);
      }

      formattedMessages.push(formattedMessage);

      // remove property contentFormatted from all elements
      formattedMessages = formattedMessages.map((msg) => {
        const updatedMsg = { ...msg };
        delete updatedMsg.contentFormatted;
        delete updatedMsg.editorText;
        delete updatedMsg.composerContent;
        return updatedMsg;
      });

      updatedMessages.push(formattedMessage);
      setMessages([...updatedMessages]);
      setIsLoading(true);
      chatContext?.setIsLoading(true);

      const db = firebaseClient.firestore();
      let allConversations = conversations;
      let conversation = currentConversation;
      if (!conversation) {
        conversation = {
          id: uuid(),
          name: updatedMessages[0]?.content?.split(" ")?.[0],
          messages: updatedMessages,
          createdAt: new Date(),
          updatedAt: new Date(),
        };

        await db
          .collection("users")
          .doc(getAccount(session)?.id)
          .collection("chatAssistConversations")
          .doc(conversation.id)
          .set(conversation);
        setCurrentConversation(conversation);
        allConversations.push(conversation);
        setConversations([...allConversations]);
      } else {
        conversation.messages = updatedMessages;
        conversation.updatedAt = new Date();
        await db
          .collection("users")
          .doc(getAccount(session)?.id)
          .collection("chatAssistConversations")
          .doc(conversation.id)
          .update(conversation);

        setCurrentConversation(conversation);
        const index = allConversations.findIndex(
          (c) => c.id === conversation?.id
        );
        allConversations[index] = conversation;
        setConversations([...allConversations]);
      }

      editorRef.current?.setContent("");
      setHtmlPrompt("");
      scrollToBottom();

      analytics.log("generate_chat_assist_message", {
        message: content,
        messageLength: content.length,
        nbChatAlreadySent: updatedMessages.length,
      });

      // request should never get here but incase it does, the old request will be cancelled
      if (controllerRef.current) {
        controllerRef.current.abort();
      }

      const controller = new AbortController();
      controllerRef.current = controller;

      const token = await getToken(session, "approveIteration");

      const response = await fetch(
        "https://tweetchat-chatv3-yiipa2itwq-uc.a.run.app",
        // "https://us-central1-ez4cast.cloudfunctions.net/tweetChat-chatV2",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            tokenuserid: session?.user?.uid ?? "",
          },
          body: JSON.stringify({
            messages: formattedMessages,
            idUser: getAccount(session)?.id,
            isInfluentLeader: true,
            description: getAccount(session)?.description,
            // description: getAccount(session)?.who,
            useBestAi: await useCredit(
              session,
              mainContext,
              "creditsBestai",
              false
            ),
            ...((systemPrompt || !useMainSystemPrompt) ? { useMainSystemPrompt: false } : {}),
            language: getAccount(session)?.languagePreferred || "English"
          }),
          signal: controllerRef.current?.signal,
        }
      );

      const reader = response.body?.getReader();
      let fullMessage = "";
      setIsLoading(false);
      for await (const chunk of readChunks(reader)) {
        const value = Buffer.from(chunk).toString("utf-8");
        fullMessage = fullMessage + value;
        let lastMessage = updatedMessages[updatedMessages.length - 1];
        // response of the api is always an AI response so this works but in case in future it changes.
        // This logic will need to be updated
        if (lastMessage?.role !== "assistant") {
          lastMessage = {
            role: "assistant",
            content: value,
            contentFormatted: formatOutput(value),
          };
          updatedMessages.push(lastMessage);
        } else {
          lastMessage.content = fullMessage;
          lastMessage.contentFormatted = formatOutput(fullMessage);
        }
        setMessages([...updatedMessages]);
      }

      finishedAudio?.play();
      controllerRef.current = null;
      chatContext?.setIsLoading(false);
      chatContext?.setHasNewMessage(true);

      conversation.messages = updatedMessages;
      conversation.updatedAt = new Date();

      db.collection("users")
        .doc(getAccount(session)?.id)
        .collection("chatAssistConversations")
        .doc(conversation.id)
        .update(conversation);

      setCurrentConversation(conversation);
      const index = allConversations.findIndex(
        (c) => c.id === conversation?.id
      );
      allConversations[index] = conversation;
      setConversations([...allConversations]);
      // no of tokens used is not implemented in backend
      // do it there itself if you need it cause doing it here is a bit buggy when I tested it
      // useCredit(session, mainContext, "creditsChat", false, json.tokenUsed)
    } catch (e) {
      console.error("Send message failed: ", e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (chatContext?.hasNewMessage) {
      if (chatContext?.isOpen) {
        chatContext?.setHasNewMessage(false);
      } else {
        toast.success("Chat Assist response generated!");
      }
    }
  }, [chatContext?.hasNewMessage]);

  // fetch conversations
  // delete them if they are more than 60 days old
  useEffect(() => {
    const getConversations = async () => {
      const db = firebaseClient.firestore();
      const docs = await db
        .collection("users")
        .doc(getAccount(session)?.id)
        .collection("chatAssistConversations")
        .get();

      let conversationsFromLast60Days: Conversation[] = [];
      let conversationsOlderThan60Days: Conversation[] = [];
      docs.docs.forEach((doc) => {
        let data = doc.data() as Conversation;
        // @ts-ignore
        data.updatedAt = data.updatedAt.toDate();
        // @ts-ignore
        data.createdAt = data.createdAt.toDate();
        const date60DaysOld = moment().subtract(60, "days").toDate();
        if (data.updatedAt < date60DaysOld) {
          conversationsOlderThan60Days.push(data);
        } else {
          conversationsFromLast60Days.push(data);
        }
      });

      setConversations(conversationsFromLast60Days);
      // delete converations older than 60 days
      if (conversationsOlderThan60Days.length > 0) {
        conversationsOlderThan60Days.forEach((c) => {
          db.collection("users")
            .doc(getAccount(session)?.id)
            .collection("chatAssistConversations")
            .doc(c.id)
            .delete();
        });
      }
    };

    if (session && isAuthenticated && !hasFetchedConversations) {
      setHasFetchedConversations(true);
      getConversations();
    }
  }, [session, isAuthenticated]);

  useEffect(() => {
    if (chatContext?.isOpen) {
      if (chatContext?.hasNewMessage) {
        scrollToBottom();
      }
      chatContext?.setHasNewMessage(false);
    }
  }, [chatContext?.isOpen]);

  function readChunks(reader) {
    return {
      async *[Symbol.asyncIterator]() {
        let readResult = await reader.read();
        while (!readResult.done) {
          yield readResult.value;
          readResult = await reader.read();
        }
      },
    };
  }

  const formatOutput = function (text) {
    // get texts between quotes "
    let regexForWholeContent = /"((.|\n|\r)*)"/g;
    let matches = text.match(regexForWholeContent);

    if (!matches || matches?.length === 0) {
      let regexForList = /"(.*?)"/g;
      matches = text.match(regexForList);
    }

    if (matches?.length > 0) {
      matches.forEach((match) => {
        if (getAccount(session)?.keywords?.includes(match.replace(/"/g, "")))
          // avoid unwanted highlights
          return;

        // remove first and last quotes
        if (match[0] === '"') match = match.substring(1);
        if (match[match.length - 1] === '"')
          match = match.substring(0, match.length - 1);

        // make sure highlighted texts always end with "\n"
        // let isEndWithBreakLine = (match[match.length-1] === "\n" || match[match.length-2] === "\n");

        let formattedText = `<span class="formatted-chat" id="formatted-chat"><span class="formatted-chat-text">${match}</span><span class="formatted-chat-buttons"><button class="formatted-chat-copy">${copyIcon}Copy</button><button class="formatted-chat-edit">${editIconLight}Edit & Post</button></span></span>`;

        // if (!isEndWithBreakLine)
        //     formattedText += "\n";

        text = text.replace('"' + match + '"', formattedText);
      });
    }

    return text;
  };

  const scrollToBottom = async function () {
    await new Promise((resolve) => setTimeout(resolve, 200));
    bottomContainerRef?.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleOnSelectPrompt = (prompt: Prompt | SavedAction) => {
    // if (prompt?.requiresPost && tweetContext?.refComposer?.current?.textState()?.text?.length < 2) {
    //   tweetContext.open();
    //   toast.error("Please write a post first");
    //   return;
    // }

    let p = prompt.prompt;

    // if (p.includes("highlight-input")) {
    const currentPostAsArray = tweetContext?.refComposer?.current
      .textState()
      ?.text?.split("\n");
    const currentPostAsHtml = currentPostAsArray
      .map(
        (p, i, arr) =>
          `<p>${i === 0 ? `"` : ""}${p}${i === arr.length - 1 ? `"` : ""}</p>`
      )
      .join("");

    p = p.replace("{{current_post}}", currentPostAsHtml);
    editorRef.current?.setContent(p);
    setHtmlPrompt(editorRef.current?.getHTML() || "");
    // } else {
    //   sendMessage(p.replace("{{current_post}}", `"${tweetContext?.refComposer?.current.textState()?.text}"`));
    // }
  };

  const handleSaveAction = async (text: string) => {
    try {
      setIsSavingAction(true);
      const action = {
        id: uuid(),
        createdAt: new Date(),
        title: text.replace(/<[^>]*>?/gm, ""),
        prompt: text,
      };

      const newSavedActions = [...savedActions];
      newSavedActions.push(action);

      await toast.promise(
        updateUser(session, { savedActions: newSavedActions }),
        {
          loading: "Saving...",
          success: () => {
            setSavedActions(newSavedActions);
            return "Action saved!";
          },
          error: () => {
            return "Failed to save action";
          },
        }
      );
    } catch (e) {
      console.error("Error in saving action: ", e);
      toast.error("Error in saving action: " + e.message);
    } finally {
      setIsSavingAction(false);
    }
  };

  useEffect(() => {
    if (session && !hasSetSavedAction) {
      setHasSetSavedAction(true);
      let actions = getAccount(session)?.savedActions ?? [];
      actions.forEach((a) => {
        a.createdAt = new Date(a.createdAt?._seconds * 1000);
      });
      setSavedActions(actions);
    }
  }, [session]);

  useImperativeHandle(ref, () => ({
    sendMessage,
    setMessages,
    messages,
    controllerRef,
    handleSaveAction,
  }));

  const toggleSidebar = (panel: Panels) => {
    setActivePanel(panel);
    if (panel === activePanel) {
      onToggleSidebar();
    } else {
      onOpenSidebar();
    }
  };

  if (!chatContext?.isOpen) {
    return <></>;
  }

  return (
    <Layout
      isSidebarOpen={isSidebarOpen}
      sidebar={
        <Flex direction="column" maxH="100%" h="100%">
          <Flex
            justifyContent="space-between"
            alignItems="center"
            p="5"
            pr="0"
            pl="3"
          >
            <Flex alignItems="center">
              <Button
                size="sm"
                variant="unstyled"
                display="flex"
                alignItems="center"
                borderBottom="2px solid transparent"
                borderColor={
                  activePanel === "action library" ? "#566F8F" : "transparent"
                }
                color={activePanel === "action library" ? "#566F8F" : "inherit"}
                borderRadius="none"
                onClick={() => setActivePanel("action library")}
              >
                <BiLibrary style={{ marginRight: "5px" }} />
                Actions Library
              </Button>
              <Button
                size="sm"
                variant="unstyled"
                display="flex"
                ml="2"
                alignItems="center"
                borderBottom="2px solid transparent"
                borderColor={
                  activePanel === "history" ? "#566F8F" : "transparent"
                }
                color={activePanel === "history" ? "#566F8F" : "inherit"}
                borderRadius="none"
                px="1"
                onClick={() => setActivePanel("history")}
              >
                <MdOutlineHistory size="16" style={{ marginRight: "5px" }} />
                History
              </Button>
            </Flex>
            <IconButton
              aria-label="Close actions library"
              variant="action"
              size="sm"
              color="primary.darkMode.default !important"
              icon={isTooSmall ? <CloseIcon /> : <CloseLibrary />}
              mr={isTooSmall ? "2" : "0"}
              onClick={() => {
                onCloseSidebar();
                setActiveIndex(undefined);
              }}
            />
          </Flex>
          <Divider />
          {activePanel === "action library" ? (
            <ActionLibrary
              onSelect={handleOnSelectPrompt}
              searchTerm={actionsLibrarySearchTerm}
              setSearchTerm={setActionsLibrarySearchTerm}
              activeIndex={activeIndex}
              setActiveIndex={setActiveIndex}
              savedActions={savedActions}
              onUpdateSavedActions={(actions) => setSavedActions([...actions])}
              editorInstance={Editor}
              imperativeHandleInstance={ImperativeHandle}
            />
          ) : null}
          {activePanel === "history" ? (
            <History
              searchTerm={historySearchTerm}
              setSearchTerm={setHistorySearchTerm}
              conversations={conversations}
              onSelect={(conversation) => {
                setCurrentConversation(conversation);
                setMessages(conversation.messages);
              }}
              onUpdate={(conversations, options) => {
                setConversations(conversations);
                if (
                  options?.type === "delete" &&
                  currentConversation?.id === options?.id
                ) {
                  setCurrentConversation(undefined);
                }
              }}
            />
          ) : null}
        </Flex>
      }
      chatWindowControls={
        <>
          {messages?.length > 0 ? (
            <Tooltip label="Refreshing will start a new conversation">
              <IconButton
                aria-label="reset chat"
                variant="action"
                icon={<RefreshIcon />}
                color="#D792FF !important"
                size="sm"
                mr="1"
                onClick={(e) => {
                  setMessages([]);
                  controllerRef.current?.abort();
                  setIsLoading(false);
                  setCurrentConversation(undefined);
                  setUseMainSystemPrompt(true);
                }}
              />
            </Tooltip>
          ) : null}
          <SettingsButton />
          <IconButton
            aria-label="Close chat assist"
            icon={<AiOutlineClose size={16} />}
            variant="action"
            ml="1"
            mr={isTooSmall ? "10" : "0"}
            size="sm"
            onClick={() => {
              chatContext?.onClose();
            }}
          />
        </>
      }
      chatWindow={
        <Messages
          messages={messages}
          isLoadingMessage={isLoading}
          onSelectHomeScreenPrompt={handleOnSelectPrompt}
          bottomMostBlock={<Box ref={bottomContainerRef} mb="10" />}
          editorInstance={Editor}
          imperativeHandleInstance={ImperativeHandle}
        />
      }
      editor={
        <>
          {chatContext?.isLoading ? (
            <Button
              size="sm"
              fontWeight="normal"
              position="absolute"
              top="-12"
              transform="translateX(-50%)"
              left="50%"
              borderRadius="2xl"
              w="fit-content"
              color={stopGeneratingColor}
              bg={popupBg}
              leftIcon={<FiSquare />}
              onClick={() => {
                controllerRef.current?.abort();
                chatContext.setIsLoading(false);
              }}
            >
              Stop Generating
            </Button>
          ) : null}
          {messages.length > 0 &&
            messages.filter((m) => m.role !== "user").length > 0 ? (
            <HStack
              spacing="2"
              alignItems="center"
              pl="3"
              pb="2"
              pt="1"
              flexWrap="wrap"
            >
              <Text fontSize="xs">Make it...</Text>
              {quickFeedbackButtons.map((btn) => (
                <Button
                  key={btn.title}
                  colorScheme="none"
                  bgGradient={GRADIENT_COLOR}
                  bgClip="text"
                  fontWeight="semibold"
                  size="xs"
                  border="1px solid"
                  borderColor={borderColor}
                  borderRadius="xl"
                  _hover={{
                    borderColor: "border.lightMode.hover",
                  }}
                  onClick={() => sendMessage(btn.textToSend)}
                >
                  {btn.title}
                </Button>
              ))}
              <IconButton
                aria-label="Open action library"
                variant="action"
                size="sm"
                icon={<PlusIcon />}
                onClick={() => {
                  onOpenSidebar();
                  setActionsLibrarySearchTerm("");
                  setActiveIndex(2);
                  setActivePanel("action library");
                }}
              />
            </HStack>
          ) : null}
          <Box
            border="1px solid"
            borderColor={borderColor}
            bg={popupBg}
            borderRadius="xl"
            py="2"
            px="3"
            boxShadow="lg"
          >
            <Editor
              initialValue={htmlPrompt}
              onChange={(parameter) => {
                setHtmlPrompt(parameter.helpers.getHTML(parameter.state));
              }}
            >
              <ImperativeHandle
                ref={editorRef}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && (e.ctrlKey || e.metaKey)) {
                    e.preventDefault();
                    sendMessage();
                    return true;
                  }

                  return false;
                }}
              />
            </Editor>
            <Flex justifyContent="space-between" mt="1">
              <Box>
                <Button
                  color={panelButtonsColor}
                  background="transparent"
                  fontWeight="semibold"
                  size="sm"
                  border="1px solid"
                  borderColor="transparent"
                  borderRadius="lg"
                  px="2"
                  _hover={{
                    borderColor: "border.lightMode.hover",
                  }}
                  leftIcon={<BiLibrary />}
                  onClick={() => toggleSidebar("action library")}
                >
                  Actions Library
                </Button>
                <Button
                  color={panelButtonsColor}
                  bg="transparent"
                  fontWeight="semibold"
                  size="sm"
                  border="1px solid"
                  borderColor="transparent"
                  borderRadius="lg"
                  px="2"
                  ml="1"
                  _hover={{
                    borderColor: "border.lightMode.hover",
                  }}
                  leftIcon={<MdOutlineHistory size="16" />}
                  onClick={() => toggleSidebar("history")}
                >
                  History
                </Button>
              </Box>
              <Box>
                {!!editorRef.current?.getTextCustom() ? (
                  <Button
                    colorScheme="none"
                    bgGradient={GRADIENT_COLOR}
                    bgClip="text"
                    fontWeight="semibold"
                    size="sm"
                    border="1px solid"
                    borderColor="transparent"
                    borderRadius="lg"
                    mr="2"
                    _hover={{
                      borderColor: "border.lightMode.hover",
                    }}
                    leftIcon={<AiOutlineSave color="#0A65C1" />}
                    isLoading={isSavingAction}
                    _loading={{
                      background: "none",
                      color: "initial",
                    }}
                    onClick={() => handleSaveAction(htmlPrompt)}
                  >
                    Save
                  </Button>
                ) : null}
                <IconButton
                  aria-label="send message"
                  variant="action"
                  size="sm"
                  icon={<SendIcon />}
                  isLoading={isLoading}
                  onClick={() => sendMessage()}
                />
              </Box>
            </Flex>
          </Box>
        </>
      }
    />
  );
});

export default ChatAssist;

const quickFeedbackButtons = [
  { title: "Shorter", textToSend: "Make it shorter" },
  { title: "Longer", textToSend: "Make it longer" },
  { title: "Bolder", textToSend: "Make it bolder" },
  { title: "More casual", textToSend: "Make it more casual" },
  { title: "More formal", textToSend: "Make it more formal" },
];
