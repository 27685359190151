import {useLinkedinApi, apiCallHookObj} from "utils/useApi";
import {shuffleArray} from "utils/helpers";
import moment from 'moment';
import { firebaseClient } from "firebaseClient";
import { getAccount } from "utils/sessionHelper";
import * as analytics from "utils/analytics";
import { Filters } from "./custom-types";

export const convertToTweet = (post) => {
    let created_at = convertLinkedinPostDate(post.postDate);

    let tweet = {
        ...post,
        created_at,
        score: 1,
        rank: 1,
        isPosting: false,
        favorite_count: post.numLikes,
        retweet_count: post.totalShares,
        full_text: post.text,
        text: post.text,
        type: "linkedin",
        name: post?.user?.firstName + " " + post?.user?.lastName,
        profilePic: post?.user?.image,
        id_str: post.id,
        noProfilePicRefresh: true,
        isPostTwitter: false,
        isLiveTweet: false,
        isRetweeted: false,
        isAutoRetweet: false,
        reply: "",
        isPlugged: false,
        isAutoDm: false,
        user: {
            ...post?.user,
            profile_image_url_https: post?.user?.image,
            description: post?.user?.about,
            image: post?.user?.image,
            id_str: post?.user?.id,
            name: post?.user?.firstName + " " + post?.user?.lastName,
        }
    };
    return tweet;
}

export async function getLastReplies(session, count=1000) {

    let previousReplies = [] as any;

    const db = firebaseClient.firestore();
    let docsPreviousReplies = await db.collection('users').doc(getAccount(session)?.id).collection("replies").orderBy("dateLastReply", "desc").limit(count).get();
    
    docsPreviousReplies.forEach(doc => {
        let reply = doc.data();
        previousReplies.push(reply);
    })

    return previousReplies;
}

export async function fetchContactInListPostFromAPI(session, peopleInList: any = [], setData, setLoader, postCount = 10) {

    let unEditedPosts: any = [];
    let iterativeCount = 0;
    let sleepTime = 0;
     
    if (peopleInList.length > 0) {

        analytics.log("hit_engage_people", {nbProfiles: peopleInList.length}, session?.user?.uid);

        let previousReplies = await getLastReplies(session);
        peopleInList = shuffleArray(peopleInList).slice(0, 10);
        peopleInList.forEach((profile: any) => {

            analytics.log("hit_engage_people_profile", {profile});

            const apiCall: apiCallHookObj = {
                mode: "func",
                args: {
                    count: postCount,
                    dashEntityUrn: profile.dashEntityUrn,
                },
                callName: "fetchPosts",
                callbackFunction: (data) => {
                    // console.group();
                    // console.log('%ccallback for data returned from linkedin api using function based api call\n','background: #222; color: #bada55', data);
                    // console.groupEnd();
                    data?.posts.forEach((post) => {
                        // console.log(post);
                        if (post.text !== "" 
                            && post.text 
                            && post?.user?.firstName 
                            && post?.user?.lastName
                            && !previousReplies.find(x => post.id_str === x.in_reply_to_status_id)
                        ) {
                            unEditedPosts.push(convertToTweet(post));
                        }
                    });

                    iterativeCount++;
                    if (iterativeCount == peopleInList.length) {
                        unEditedPosts.sort(sortLinkedPostsViaDate);
                        const tweets = {
                            "tweets": unEditedPosts,
                            error: null,
                            success: 1
                        };
                        setData(tweets);
                        //console.log('returned data from api',tweets);
                        setLoader(false);
                    }
                }
            };
            setTimeout(() => { useLinkedinApi(apiCall); }, (900 * (iterativeCount + 1)));
        });
    }
    else {
        setData(null);
        setLoader(false);
    }
}


export const convertLinkedinPostDate = (postDate) => {
    if(!postDate) return moment.utc(moment().format()).format();

    const seperatePostDate = [...postDate.matchAll(/(^\d+)?(\w+)?/g)];
    const timeDurPart = seperatePostDate[0][1] || undefined;
    const stringDurPart = seperatePostDate[0][2] || undefined;
    
    const linkedinDateFormat = {
        'mo':'M',
        'yr':'y',
        'd':'d',
        'h':'h',
        'w':'w',
        'q':'Q',
        'm':'m',
        's':'s',
        'ms':'ms',
        undefined:undefined,
    };

    let created_at: string = moment.utc(moment().format()).format();
    
    if(timeDurPart != undefined && stringDurPart != undefined && linkedinDateFormat[stringDurPart] != undefined) {
        created_at = moment.utc(moment().subtract(timeDurPart, linkedinDateFormat[stringDurPart])).format();
    }
    return created_at;
}

const sortLinkedPostsViaDate = (postA, postB): Number => {
    if(!postA || !postB || !postB.created_at || !postA.created_at) return 0;

    const postACreatedAt = moment(postA.created_at).unix() || 0;
    const postBCreatedAt = moment(postB.created_at).unix() || 0;

    return Number(postBCreatedAt) - Number(postACreatedAt);
}

export enum SortType {
    MOST_RECENT = "Most Recent",
    OLDEST_FIRST = "Oldest First",
    MOST_FOLLOWERS = "Most Followers",
    LEAST_FOLLOWERS = "Least Followers",
    MOST_LIKES = "Most Likes",
    LEAST_LIKES = "Least Likes",
    MOST_RELEVANT = "Most Relevant",
    LEAST_RELEVANT = "Least Relevant",
    MOST_COMMENTS = "Most Comments",
    LEAST_COMMENTS = "Least Comments",
    MOST_ENGAGEMENT = "Most Engagement",
    LEAST_ENGAGEMENT = "Least Engagement",
    MOST_SHARES = "Most Shares",
    LEAST_SHARES = "Least Shares",
    RTC_RECENT = "Recent Comments",
    RTC_OLDEST = "Oldest Comments",
    RTC_RELEVANT = "Relevant Comments"
}

const customSorter = {
    "Most Recent": (data) => {
                        return data.sort(
                            (a, b) => new Date(b?.created_at).getTime() - new Date(a.created_at).getTime()
                        );
                    },
    "Oldest First": (data) => {
        return data.sort(
            (a, b) => new Date(a?.created_at).getTime() - new Date(b.created_at).getTime()
        );
    },
    "Most Followers":  (data) => {
                            return data.sort(
                                (a, b) => b.user.followersCount - a.user.followersCount
                            );
                    },
    "Least Followers":  (data) => {
                            return data.sort(
                                (a, b) => a.user.followersCount - b.user.followersCount
                            );
                    },
    "Most Likes":  (data) => {
                        return data.sort(
                            (a, b) => b.numLikes - a.numLikes
                        );
                    },
    "Least Likes":  (data) => {
                        return data.sort(
                            (a, b) => a.numLikes - b.numLikes
                        );
                    },
    "Most Comments":  (data) => {
                            return data.sort(
                                (a, b) => b.numComments - a.numComments
                            );
                        },
    "Least Comments":  (data) => {
                            return data.sort(
                                (a, b) => a.numComments - b.numComments
                            );
                        },
    "Most Engagement":  (data) => {
                            return data.sort(
                                (a, b) => b.totalEngagement - a.totalEngagement
                            );
                        },
    "Least Engagement":  (data) => {
                            return data.sort(
                                (a, b) => a.totalEngagement - b.totalEngagement
                            );
                        },
    "Most Shares":  (data) => {
                        return data.sort(
                            (a, b) => b.totalShares - a.totalShares
                        );
                    },
    "Least Shares":  (data) => {
                            return data.sort(
                                (a, b) => a.totalShares - b.totalShares
                            );
                        },
    "Most Relevant":    (data) => {
                            return data.sort(
                                (a, b) => b.rank - a.rank
                            );
                        },
    "Least Relevant":  (data) => {
                            return data.sort(
                            (a, b) => a.rank - b.rank
                            );
                        },
    "Relevant Comments":  (data) => { //for reply-to-comments //not implemented
                                return data;
                    },
    undefined:  (data) => {
                    return data.sort(
                        (a, b) => new Date(b?.created_at).getTime() - new Date(a.created_at).getTime()
                    );
                },
}

export const sortPosts = (data: any[], sortby: string) => {
    if (data && data?.length)
        data = customSorter[sortby](data);

    return data;
};

export const sortValuesByMode = {
    "bestforyou": [
        SortType.MOST_RECENT,
        SortType.OLDEST_FIRST,
        SortType.MOST_FOLLOWERS,
        SortType.LEAST_FOLLOWERS,
        SortType.MOST_RELEVANT,
        SortType.LEAST_RELEVANT
    ],
    "people":[
        SortType.MOST_RECENT,
        SortType.OLDEST_FIRST,
        SortType.MOST_LIKES,
        SortType.LEAST_LIKES,
        SortType.MOST_FOLLOWERS,
        SortType.LEAST_FOLLOWERS,
        SortType.MOST_COMMENTS,
        SortType.LEAST_COMMENTS,
        SortType.MOST_ENGAGEMENT,
        SortType.LEAST_ENGAGEMENT,
        SortType.MOST_SHARES,
        SortType.LEAST_SHARES,
    ],
    "org": [
        SortType.MOST_RECENT,
        SortType.OLDEST_FIRST,
        SortType.MOST_FOLLOWERS,
        SortType.LEAST_FOLLOWERS,
        SortType.MOST_RELEVANT,
        SortType.LEAST_RELEVANT
    ],
    "reply-to-comment": [
        SortType.MOST_RECENT,
        SortType.OLDEST_FIRST,
    ]
}

export const defaultFilters: Filters = {
    minFollowers: 0,
    maxFollowers: 999999999,
    minDaysOld: 0,
    maxDaysOld: 2,
    negativeKeywords: [],
    positiveKeywords: [],
    noMediaAndLinks: false,
    noHashtags: false,
};

export const ENGAGE_FILTER_KEY = "searchTopic_engage_filters";

export const sleep = ms => new Promise(r => setTimeout(r, ms));